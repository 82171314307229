import React, { useState, useEffect, useCallback } from 'react';
import OfferForm from './OfferForm';
import Results from './Results';
import Difference from './Difference';
import { calculateResults } from '../utils/calculateResults';
import '../App.css';

const translations = {
    en: {
        title: "B2B vs COE Employment Calculator",
        description: "Compare net salary under B2B vs. Contract of Employment in Poland.",
        calculate: "Calculate",
        offer1: "Offer 1",
        offer2: "Offer 2",
        results1: "Results for Offer 1",
        results2: "Results for Offer 2",
        difference: "Difference Between Offers",
        switchLanguage: "Switch to Polish"
    },
    pl: {
        title: "Kalkulator Zatrudnienia B2B vs UoP",
        description: "Porównaj wynagrodzenie netto w ramach B2B vs. Umowa o Pracę w Polsce.",
        calculate: "Oblicz",
        offer1: "Oferta 1",
        offer2: "Oferta 2",
        results1: "Wyniki dla Oferty 1",
        results2: "Wyniki dla Oferty 2",
        difference: "Różnica między ofertami",
        switchLanguage: "Przełącz na angielski"
    }
};

function Calculator() {
    const [input1, setInput1] = useState({ grossIncome: '10000', educationBudget: '', additionalCosts: '', employmentType: 'b2b', taxType: 'flat', zus: false });
    const [input2, setInput2] = useState({ grossIncome: '10000', educationBudget: '', additionalCosts: '', employmentType: 'coe', taxType: 'flat', zus: false });
    const [result, setResult] = useState({});
    const [language, setLanguage] = useState('en');

    const handleInputChange = (e, setInput) => {
        const { name, value, type, checked } = e.target;
        setInput(prevState => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
    };

    const calculate = useCallback(() => {
        setResult({ winner: 'Calculating...', calculating: true });
        setTimeout(() => {
            const newResult = calculateResults(input1, input2);
            setResult(newResult);
        }, 1000);
    }, [input1, input2]);

    const toggleLanguage = () => {
        setLanguage(prevLanguage => (prevLanguage === 'en' ? 'pl' : 'en'));
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') calculate();
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [calculate]);

    const t = translations[language];

    return (
        <div className="container mx-auto bg-white rounded-lg shadow-lg p-8">
            <h1 className="text-3xl font-bold text-center text-blue-600 mb-8">{t.title}</h1>
            <p className="text-center text-lg mb-8">{t.description}</p>
            <button onClick={toggleLanguage} className="block w-full max-w-xs mx-auto bg-blue-500 text-white font-bold py-2 rounded hover:bg-blue-600 transition duration-300 mb-4">
                {t.switchLanguage}
            </button>
            <div className="grid grid-cols-2 gap-4">
                <OfferForm id="offer1" input={input1} setInput={setInput1} handleInputChange={handleInputChange} language={language} />
                <OfferForm id="offer2" input={input2} setInput={setInput2} handleInputChange={handleInputChange} language={language} />
            </div>
            <button onClick={calculate} className="block w-full max-w-xs mx-auto bg-green-500 text-white font-bold py-2 rounded hover:bg-green-600 transition duration-300">
                {t.calculate}
            </button>
            <div className="grid grid-cols-2 gap-4 mt-8">
                <Results title={t.results1} result={result} prefix="1" />
                <Results title={t.results2} result={result} prefix="2" />
            </div>
            <Difference result={result} title={t.difference} />
        </div>
    );
}

export default Calculator;
