import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';

const translations = {
    en: {
        employmentType: "Select Employment Type:",
        grossIncome: "Enter Gross Monthly Income (PLN):",
        hourlyRate: "Enter Hourly Rate (PLN):",
        incomeType: "Select Income Type:",
        monthly: "Monthly",
        hourly: "Hourly",
        educationBudget: "Enter Yearly Education Budget (PLN):",
        additionalCosts: "Enter Additional Monthly Costs (PLN):",
        taxType: "Select Tax Type:",
        zus: "Include ZUS:",
        zusType: "Select ZUS Type:",
        largeZus: "Large ZUS",
        smallZus: "Small ZUS",
        b2b: "B2B",
        coe: "COE",
        tooltips: {
            incomeType: "Monthly: Gross Monthly Income\nHourly: Hourly Rate * 160 hours/month",
            taxType: "Flat Tax: 19%\nProgressive Tax: 17% up to 85,528 PLN, then 32%\nLump Sum: Various rates depending on the type of business",
            zusType: "Large ZUS: 1500 PLN/month\nSmall ZUS: 600 PLN/month"
        }
    },
    pl: {
        employmentType: "Wybierz rodzaj zatrudnienia:",
        grossIncome: "Wprowadź miesięczny dochód brutto (PLN):",
        hourlyRate: "Wprowadź stawkę godzinową (PLN):",
        incomeType: "Wybierz rodzaj dochodu:",
        monthly: "Miesięczny",
        hourly: "Godzinowy",
        educationBudget: "Wprowadź roczny budżet na edukację (PLN):",
        additionalCosts: "Wprowadź dodatkowe miesięczne koszty (PLN):",
        taxType: "Wybierz rodzaj podatku:",
        zus: "Uwzględnij ZUS:",
        zusType: "Wybierz rodzaj ZUS:",
        largeZus: "Duży ZUS",
        smallZus: "Mały ZUS",
        b2b: "B2B",
        coe: "UoP",
        tooltips: {
            incomeType: "Miesięczny: Miesięczny dochód brutto\nGodzinowy: Stawka godzinowa * 160 godzin/miesiąc",
            taxType: "Podatek liniowy: 19%\nPodatek progresywny: 17% do 85,528 PLN, potem 32%\nRyczałt: Różne stawki w zależności od rodzaju działalności",
            zusType: "Duży ZUS: 1500 PLN/miesiąc\nMały ZUS: 600 PLN/miesiąc"
        }
    }
};

function OfferForm({ id, input, setInput, handleInputChange, language }) {
    const t = translations[language];
    const [incomeType, setIncomeType] = useState('monthly');

    const handleIncomeTypeChange = (e) => {
        setIncomeType(e.target.value);
        setInput(prevState => ({ ...prevState, grossIncome: '', hourlyRate: '' }));
    };

    return (
        <div id={id} className="offer">
            <h2 className="text-2xl font-bold text-center text-blue-600 mb-4">{id === 'offer1' ? t.offer1 : t.offer2}</h2>
            <div className="form-group text-center mb-6">
                <label htmlFor={`employmentType${id}`} className="block font-bold mb-2">{t.employmentType}</label>
                <select name="employmentType" id={`employmentType${id}`} value={input.employmentType} onChange={(e) => handleInputChange(e, setInput)} className="w-full max-w-xs mx-auto p-2 border border-gray-300 rounded shadow-sm">
                    <option value="b2b">{t.b2b}</option>
                    <option value="coe">{t.coe}</option>
                </select>
            </div>
            {input.employmentType === 'b2b' && (
                <div className="form-group text-center mb-6">
                    <label htmlFor={`incomeType${id}`} className="block font-bold mb-2">
                        {t.incomeType}
                        <span data-tooltip-id={`tooltip-incomeType-${id}`} className="ml-2 text-blue-500 cursor-pointer">?</span>
                        <Tooltip id={`tooltip-incomeType-${id}`} place="top" type="dark" effect="solid">
                            {t.tooltips.incomeType}
                        </Tooltip>
                    </label>
                    <select name="incomeType" id={`incomeType${id}`} value={incomeType} onChange={handleIncomeTypeChange} className="w-full max-w-xs mx-auto p-2 border border-gray-300 rounded shadow-sm">
                        <option value="monthly">{t.monthly}</option>
                        <option value="hourly">{t.hourly}</option>
                    </select>
                </div>
            )}
            {input.employmentType === 'b2b' && incomeType === 'hourly' ? (
                <div className="form-group text-center mb-6">
                    <label htmlFor={`hourlyRate${id}`} className="block font-bold mb-2">{t.hourlyRate}</label>
                    <input type="number" name="hourlyRate" id={`hourlyRate${id}`} value={input.hourlyRate} onChange={(e) => handleInputChange(e, setInput)} placeholder="e.g. 100" className="w-full max-w-xs mx-auto p-2 border border-gray-300 rounded shadow-sm" />
                </div>
            ) : (
                <div className="form-group text-center mb-6">
                    <label htmlFor={`grossIncome${id}`} className="block font-bold mb-2">{t.grossIncome}</label>
                    <input type="number" name="grossIncome" id={`grossIncome${id}`} value={input.grossIncome} onChange={(e) => handleInputChange(e, setInput)} placeholder="e.g. 10000" className="w-full max-w-xs mx-auto p-2 border border-gray-300 rounded shadow-sm" />
                </div>
            )}
            <div className="form-group text-center mb-6">
                <label htmlFor={`educationBudget${id}`} className="block font-bold mb-2">{t.educationBudget}</label>
                <input type="number" name="educationBudget" id={`educationBudget${id}`} value={input.educationBudget} onChange={(e) => handleInputChange(e, setInput)} placeholder="e.g. 1200" className="w-full max-w-xs mx-auto p-2 border border-gray-300 rounded shadow-sm" />
            </div>
            <div className="form-group text-center mb-6">
                <label htmlFor={`additionalCosts${id}`} className="block font-bold mb-2">{t.additionalCosts}</label>
                <input type="number" name="additionalCosts" id={`additionalCosts${id}`} value={input.additionalCosts} onChange={(e) => handleInputChange(e, setInput)} placeholder="e.g. 200" className="w-full max-w-xs mx-auto p-2 border border-gray-300 rounded shadow-sm" />
            </div>
            {input.employmentType === 'b2b' && (
                <>
                    <div className="form-group text-center mb-6">
                        <label htmlFor={`taxType${id}`} className="block font-bold mb-2">
                            {t.taxType}
                            <span data-tooltip-id={`tooltip-taxType-${id}`} className="ml-2 text-blue-500 cursor-pointer">?</span>
                            <Tooltip id={`tooltip-taxType-${id}`} place="top" type="dark" effect="solid">
                                {t.tooltips.taxType}
                            </Tooltip>
                        </label>
                        <select name="taxType" id={`taxType${id}`} value={input.taxType} onChange={(e) => handleInputChange(e, setInput)} className="w-full max-w-xs mx-auto p-2 border border-gray-300 rounded shadow-sm">
                            <option value="flat">Podatek liniowy</option>
                            <option value="progressive">Podatek progresywny</option>
                            <option value="lumpSum">Ryczałt</option>
                        </select>
                    </div>
                    <div className="form-group text-center mb-6">
                        <label htmlFor={`zus${id}`} className="block font-bold mb-2">
                            {t.zus}
                            <span data-tooltip-id={`tooltip-zusType-${id}`} className="ml-2 text-blue-500 cursor-pointer">?</span>
                            <Tooltip id={`tooltip-zusType-${id}`} place="top" type="dark" effect="solid">
                                {t.tooltips.zusType}
                            </Tooltip>
                        </label>
                        <input type="checkbox" name="zus" id={`zus${id}`} checked={input.zus} onChange={(e) => handleInputChange(e, setInput)} className="mx-auto" />
                    </div>
                    {input.zus && (
                        <div className="form-group text-center mb-6">
                            <label htmlFor={`zusType${id}`} className="block font-bold mb-2">{t.zusType}</label>
                            <select name="zusType" id={`zusType${id}`} value={input.zusType} onChange={(e) => handleInputChange(e, setInput)} className="w-full max-w-xs mx-auto p-2 border border-gray-300 rounded shadow-sm">
                                <option value="large">{t.largeZus}</option>
                                <option value="small">{t.smallZus}</option>
                            </select>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default OfferForm;
