export const calculateResults = (input1, input2) => {
    const calculateGrossIncome = (input) => {
        if (input.employmentType === 'b2b' && input.hourlyRate) {
            const hoursPerMonth = 160; // Assuming 40 hours per week and 4 weeks per month
            return parseFloat(input.hourlyRate) * hoursPerMonth;
        }
        return parseFloat(input.grossIncome) || 0;
    };

    const calculateZus = (zusType) => {
        const largeZus = 1500; // Example value for large ZUS
        const smallZus = 600; // Example value for small ZUS
        return zusType === 'large' ? largeZus : smallZus;
    };

    const calculateTax = (grossIncome, taxType) => {
        switch (taxType) {
            case 'flat':
                return grossIncome * 0.19;
            case 'progressive':
                if (grossIncome <= 85528) {
                    return grossIncome * 0.17;
                } else {
                    return (85528 * 0.17) + ((grossIncome - 85528) * 0.32);
                }
            case 'lumpSum':
                // Example lump sum tax rate, this should be adjusted based on the type of business
                return grossIncome * 0.15;
            default:
                return 0;
        }
    };

    const grossIncome1 = calculateGrossIncome(input1);
    const educationBudget1 = parseFloat(input1.educationBudget) || 0;
    const additionalCosts1 = parseFloat(input1.additionalCosts) || 0;
    const taxType1 = input1.taxType;
    const zus1 = input1.zus ? calculateZus(input1.zusType) : 0;
    const tax1 = calculateTax(grossIncome1, taxType1);

    const grossIncome2 = calculateGrossIncome(input2);
    const educationBudget2 = parseFloat(input2.educationBudget) || 0;
    const additionalCosts2 = parseFloat(input2.additionalCosts) || 0;
    const taxType2 = input2.taxType;
    const zus2 = input2.zus ? calculateZus(input2.zusType) : 0;
    const tax2 = calculateTax(grossIncome2, taxType2);

    // ... existing calculation logic ...

    return {
        gross1: grossIncome1.toFixed(2),
        tax1: tax1.toFixed(2),
        net1: (grossIncome1 - tax1 - zus1).toFixed(2),
        educationMonthly1: (educationBudget1 / 12).toFixed(2),
        additionalCostsDisplay1: additionalCosts1.toFixed(2),
        adjustedCompensation1: (grossIncome1 - tax1 - zus1 - additionalCosts1 + (educationBudget1 / 12)).toFixed(2),
        gross2: grossIncome2.toFixed(2),
        tax2: tax2.toFixed(2),
        net2: (grossIncome2 - tax2 - zus2).toFixed(2),
        educationMonthly2: (educationBudget2 / 12).toFixed(2),
        additionalCostsDisplay2: additionalCosts2.toFixed(2),
        adjustedCompensation2: (grossIncome2 - tax2 - zus2 - additionalCosts2 + (educationBudget2 / 12)).toFixed(2),
        difference: (Math.abs((grossIncome1 - tax1 - zus1 - additionalCosts1 + (educationBudget1 / 12)) - (grossIncome2 - tax2 - zus2 - additionalCosts2 + (educationBudget2 / 12)))).toFixed(2),
        winner: (grossIncome1 - tax1 - zus1 - additionalCosts1 + (educationBudget1 / 12)) > (grossIncome2 - tax2 - zus2 - additionalCosts2 + (educationBudget2 / 12)) ? 'Offer 1' : 'Offer 2',
        calculating: false
    };
};
