import React from 'react';

function Results({ title, result, prefix }) {
    return (
        <div>
            <h3 className="text-2xl font-bold text-center text-blue-600 mb-4">{title}</h3>
            <p className="text-center"><strong>Gross Income:</strong> PLN {result[`gross${prefix}`]}</p>
            <p className="text-center"><strong>Tax:</strong> PLN {result[`tax${prefix}`]}</p>
            <p className="text-center"><strong>Net Salary:</strong> PLN {result[`net${prefix}`]}</p>
            <p className="text-center"><strong>Monthly Education Budget:</strong> PLN {result[`educationMonthly${prefix}`]}</p>
            <p className="text-center"><strong>Additional Monthly Costs:</strong> PLN {result[`additionalCostsDisplay${prefix}`]}</p>
            <p className="text-center"><strong>Adjusted Compensation:</strong> PLN {result[`adjustedCompensation${prefix}`]}</p>
        </div>
    );
}

export default Results;
