import React from 'react';

function Difference({ result, title }) {
    return (
        <div className="mt-8">
            <h3 className="text-2xl font-bold text-center text-blue-600 mb-4">{title}</h3>
            <p className="text-center"><strong>Difference in Adjusted Compensation:</strong> PLN {result.difference}</p>
            <p className="text-center"><strong>Winner:</strong> <span className={result.calculating ? 'calculating' : 'winner'}>{result.winner}</span></p>
        </div>
    );
}

export default Difference;
